import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import polishing_machine from "../../../assets/images/Product/Product Category/Polishing Machine.jpg";
import scrubber from "../../../assets/images/Product/Product Category/Scrubber.jpg";
import sweeper from "../../../assets/images/Product/Product Category/Sweeper.jpg";
import carpet_clean from "../../../assets/images/Product/Product Category/carpat-cleaning-machine.jpg";
import vacuum from "../../../assets/images/Product/Product Category/VACUUM.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import "./menu.css";
import {
  polishingCat,
  ScubberWalkBehind,
  ScubberRideOn,
  RideOnSweepers,
  WalkBehindSweepers,
  carpetClean,
  vaccum,
} from "../../Product Components/Product List/productList";

function Menu() {
  const [hoveredCategory, setHoveredCategory] = useState(null);

  return (
    <nav className="navbar navbar-expand-lg home-navbar border d-none d-md-block">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/" exact>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                About Wiggo
              </NavLink>
            </li>
            <li
              className="nav-item dropdown text-dark"
              onMouseEnter={() => setHoveredCategory("category")}
              onMouseLeave={() => setHoveredCategory(null)}
            >
              <NavLink className="nav-link" to="/category" role="button">
                Product Category{" "}
                <FontAwesomeIcon icon={faSortDown} className="mb-1" />
              </NavLink>
              {hoveredCategory && (
                <DesktopProductCategory
                  setHoveredCategory={setHoveredCategory}
                />
              )}
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/blog">
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

function DesktopProductCategory({ setHoveredCategory }) {
  const categories = [
    {
      name: "Polishing Machine",
      products: polishingCat.map((item) => ({
        id: item.id,
        productName: item.productName,
        image: item.himage,
        url: "/category/polishing-machine",
      })),
      url: "/category/polishing-machine",
    },
    {
      name: "Ride On Scrubber",
      products: ScubberRideOn.map((item) => ({
        id: item.id,
        productName: item.productName,
        image: item.himage,
        url: "/category/scrubber",
      })),
      url: "/category/scrubber",
    },
    {
      name: "Walk Behind Scrubber",
      products: ScubberWalkBehind.map((item) => ({
        id: item.id,
        productName: item.productName,
        image: item.himage,
        url: "/category/scrubber",
      })),
      url: "/category/scrubber",
    },
    {
      name: "Ride On Sweeper",
      products: RideOnSweepers.map((item) => ({
        id: item.id,
        productName: item.productName,
        image: item.himage,
        url: "/category/sweeper",
      })),
      url: "/category/sweeper",
    },
    {
      name: "Walk On Sweeper",
      products: WalkBehindSweepers.map((item) => ({
        id: item.id,
        productName: item.productName,
        image: item.himage,
        url: "/category/sweeper",
      })),
      url: "/category/sweeper",
    },
    {
      name: "Carpet Cleaners",
      products: carpetClean.map((item) => ({
        id: item.id,
        productName: item.productName,
        image: item.himage,
        url: "/category/carpet-cleaners",
      })),
      url: "/category/carpet-cleaners",
    },
    {
      name: "Vacuum",
      products: vaccum.map((item) => ({
        id: item.id,
        productName: item.productName,
        image: item.himage,
        url: "/category/vacuum",
      })),
      url: "/category/vacuum",
    },
  ];

  const [hoveredProducts, setHoveredProducts] = useState(
    categories[0].products
  );

  return (
    <div
      className="dropdown-menu product-dropdown d-flex"
      onMouseLeave={() => setHoveredProducts(null)}
    >
      <div className="category-list">
        {categories.map((category, index) => (
          <div
            key={index}
            className="category-item-h"
            onMouseEnter={() => setHoveredProducts(category.products)}
          >
            <Link to={`${category.url}`}>
              {/* <img src={category.image} alt={category.name} className="category-image" /> */}
              <p className="category-name">{category.name}</p>
            </Link>
          </div>
        ))}
      </div>
      {hoveredProducts && (
        <div className="product-list">
          {hoveredProducts.map((product, index) => (
            <div key={index} className="product-item">
              <Link to={`${product.url}/${product.id}`}>
                <img
                  src={product.image}
                  alt={product.productName}
                  className="product-image img-fluid"
                />
                <p>{product.productName}</p>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Menu;
