import React from 'react'
import Header from '../Home Coomponents/Header/Header'
import Menu from '../Home Coomponents/Menu/Menu'

function AboutsUs() {
  return (
    <>
   <Header/>
   <Menu/>
    </>
  )
}

export default AboutsUs