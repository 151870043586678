// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB9GEJxJkDT9-HKvRiHB9qXe2PH94sFgdk",
//   authDomain: "mahendraram-fa05b.firebaseapp.com",
//   projectId: "mahendraram-fa05b",
//   storageBucket: "mahendraram-fa05b.appspot.com",
//   messagingSenderId: "210239020042",
//   appId: "1:210239020042:web:cb76709b6b39ea2dc513f3",
//   measurementId: "G-2MK67JG6SS"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// export { db };


import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDE4sLV1GBMMeTnI5pYaejARWk3cNDSDT8",
  authDomain: "wiggo-99675.firebaseapp.com",
  projectId: "wiggo-99675",
  storageBucket: "wiggo-99675.appspot.com",
  messagingSenderId: "1037243676032",
  appId: "1:1037243676032:web:de6febfdea57341e643d65",
  measurementId: "G-KGLXP57HG5"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
