import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Button, Table, Spinner } from "react-bootstrap";
import * as XLSX from "xlsx";
import { DocumentDownload } from "iconsax-react";
import Header from "../Components/Home Coomponents/Header/Header";
import Menu from "../Components/Home Coomponents/Menu/Menu";
import Footer from "../Components/Home Coomponents/Footer/Footer";

function InquiryList() {
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [contactsPerPage] = useState(25); // Contacts per page

  useEffect(() => {
    setSelectAll(selectedContacts.length === contacts.length);
  }, [selectedContacts, contacts.length]);

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true);
      try {
        const contactsCollection = collection(db, "contacts");
        const contactsSnapshot = await getDocs(contactsCollection);
        const contactsList = contactsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContacts(contactsList);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const handleCheckboxChange = (contact) => {
    setSelectAll(false);
    if (selectedContacts.includes(contact)) {
      setSelectedContacts(selectedContacts.filter((c) => c.id !== contact.id));
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(contacts);
    }
    setSelectAll(!selectAll);
  };

  const handleExport = () => {
    const csvHeader = `Sr.No.,Name,Email,Number,City,Message`;

    const csvRows = selectedContacts.map((contact, index) => {
      return `${index + 1},${contact.name},${contact.email},${contact.number},${
        contact.city
      },${contact.message}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contact_inquirys.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSelectedContacts([]);
  };

  const handleRowClick = (contact) => {
    handleCheckboxChange(contact);
  };

  // Calculate displayed contacts based on current page
  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = contacts.slice(
    indexOfFirstContact,
    indexOfLastContact
  );

  // Handle pagination
  const totalPages = Math.ceil(contacts.length / contactsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Header />
      <Menu />
      <div className="container inquiry-contact">
        {loading ? (
          <div className="text-center mt-4">
            <Spinner animation="border" variant="primary" />{" "}
            <p>Loading Inquiry List...</p>
          </div>
        ) : (
          <>
            {contacts.length == 0 ? (
              <>
                <div className="text-center">
                  <h3>No Inquiry List Found..</h3>
                </div>
              </>
            ) : (
              <>
                <div className="text-end mb-3">
                  <Button
                    variant="warning"
                    onClick={handleExport}
                    className="text-center"
                    disabled={selectedContacts.length === 0}
                  >
                    Export <DocumentDownload className="ms-1" />
                  </Button>
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </th>
                      <th>SR. No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Number</th>
                      <th>City</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentContacts.map((contact, index) => (
                      <tr
                        key={contact.id}
                        onClick={() => handleRowClick(contact)}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedContacts.includes(contact)}
                            onChange={() => handleCheckboxChange(contact)}
                          />
                        </td>
                        <td>{index + indexOfFirstContact + 1}</td>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.number}</td>
                        <td>{contact.city}</td>
                        <td>{contact.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="pagination text-center">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <Button
                      key={i + 1}
                      variant={currentPage === i + 1 ? "primary" : "secondary"}
                      onClick={() => handlePageChange(i + 1)}
                      className="me-1"
                    >
                      {i + 1}
                    </Button>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default InquiryList;
