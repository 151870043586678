import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/style/mainStyle.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Components/Home Coomponents/Header/Header";
import Menu from "./Components/Home Coomponents/Menu/Menu";
import About from "./Pages/About";
import Product from "./Pages/Product";
import ScrollToTop from "./Components/Scroll To Top/ScrollToTop";
import ContactUs from "./Components/ContactUs/ContactUs";
import AboutsUs from "./Components/About us/AboutsUs";
import ThankYou from "./Pages/ThankYou";
import InquiryList from "./Pages/InquiryList";
import Loader from "./Components/Loader/Loader";
import { useEffect, useState } from "react";

function App() {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutsUs />} />
        <Route path="/category/polishing-machine" element={<Product />} />
        <Route path="/category/scrubber" element={<Product />} />
        <Route path="/category/sweeper" element={<Product />} />
        <Route path="/category/carpet-cleaners" element={<Product />} />
        <Route path="/category/vacuum" element={<Product />} />
        <Route
          path="/category/:categoryName/:productId"
          element={<Product />}
        />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/contact/inquiry-list" element={<InquiryList />} />
      </Routes>
    </Router>
  );
}

export default App;
