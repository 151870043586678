import React, { useState } from "react";
import Header from "../Home Coomponents/Header/Header";
import Menu from "../Home Coomponents/Menu/Menu";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { db } from "./../../utils/firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import emailjs from "emailjs-com";
import Footer from "../Home Coomponents/Footer/Footer";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Contectus.css'
function ContactUs() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    city: "",
    message: "",
    captcha: null,
  });
  const [captchaError, setCaptchaError] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.name) {
      formErrors.name = "Name is required";
      isValid = false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      formErrors.email = "Invalid email address";
      isValid = false;
    }

    if (!formData.number) {
      formErrors.number = "Phone number is required";
      isValid = false;
    } else if (formData.number.length < 10) {
      formErrors.number = "Phone number must be at least 10 digits";
      isValid = false;
    }

    if (!formData.city) {
      formErrors.city = "City is required";
      isValid = false;
    }

    if (!formData.message) {
      formErrors.message = "Message is required";
      isValid = false;
    }

    if (!formData.captcha) {
      setCaptchaError("Please complete the CAPTCHA");
      isValid = false;
    } else {
      setCaptchaError(null);
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        await addDoc(collection(db, "contacts"), {
          name: formData.name,
          email: formData.email,
          number: formData.number,
          city: formData.city,
          message: formData.message,
        });

        const templateParams = {
          name: formData.name,
          email: formData.email,
          number: formData.number,
          city: formData.city,
          message: formData.message,
        };

        emailjs
          .send(
            "service_wga775g",
            "template_5bwf4kf",
            templateParams,
            "V27Ywh6B9UQFDfMtt"
          )
          .then((response) => {
            console.log(
              "Email sent successfully:",
              response.status,
              response.text
            );
          })
          .catch((err) => {
            console.error("Error sending email:", err);
          });
        setFormData({ name: "", email: "", number: "", city: "", message: "" });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      console.log("Form submitted:", formData);
      setFormData({ name: "", email: "", number: "", city: "", message: "" });
    }
  };

  const onCaptchaChange = (value) => {
    setFormData({ ...formData, captcha: value });
  };

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const center = {
    lat: 19.2183,
    lng: 72.9781,
  };

  return (
    <>
      <Header />
      <Menu />
      <div className="container mt-3 conatactus-page">
        <div className="row footer-container">
          <div className="col-md-6 left-side">
            <h2 className="py-2 text-warning">Contact Us</h2>
            <hr className="text-warning" />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3 p-3">
                  <label htmlFor="number" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      errors.number ? "is-invalid" : ""
                    }`}
                    id="number"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                  />
                  {errors.number && (
                    <div className="text-danger">{errors.number}</div>
                  )}
                </div>

                <div className="col-md-6 mb-3 p-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.city ? "is-invalid" : ""
                    }`}
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                  {errors.city && (
                    <div className="text-danger">{errors.city}</div>
                  )}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className={`form-control ${
                    errors.message ? "is-invalid" : ""
                  }`}
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && (
                  <div className="text-danger">{errors.message}</div>
                )}
              </div>

              <div className="mb-3">
                <ReCAPTCHA
                  sitekey="6LeXNmQqAAAAAKMSdYdcdFZeBOnt0Fa2F9DHqJFI"
                  onChange={onCaptchaChange}
                  size="visible"
                />
                {captchaError && (
                  <div className="text-danger">{captchaError}</div>
                )}
              </div>
              <button type="submit" className="btn btn-warning">
                Submit
              </button>
            </form>
          </div>
          <div className="col-md-6 right-side">
            <h2 className="py-2 text-warning">Contact Info</h2>
            <hr className="text-warning" />
            <h3>Visit Us</h3>
            <hr className="text-warning m-0" />
            <h5>ECOKLEEN EQUIPMENTS PRIVATE LIMITED</h5>
            <p className="m-0">
              Office No 108, Suvidha Palace, First Floor, Kanakia Road,
            </p>
            <p>near kings banquet, Mira road east, Thane 401107</p>

            <div className="row">
              <h3>Let's Talk</h3>
              <hr className="text-warning" />

              {/* First Column */}
              <div className="col-md-6">
                <p>
                  Phone: <a href="tel:+918828802929">+91 88288 02929</a>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:ecokleenequip@gmail.com">
                    ecokleenequip@gmail.com
                  </a>
                </p>
              </div>
              {/* Second Column */}
              <div className="col-md-6">
                <p>
                  Info:{" "}
                  <a href="mailto:info@wiggoautomation.com">
                    info@wiggoautomation.com
                  </a>
                </p>
                <p>
                  Website:{" "}
                  <a
                    href="https://www.wiggoautomation.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.wiggoautomation.com
                  </a>
                </p>
              </div>
              <h3 className="mb-2 mt-4">Follow Us</h3>
              <hr className="text-warning m-1" />

              <div className="social-icons">
                <a href="#" className="social-icon">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="#" className="social-icon">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="#" className="social-icon">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="#" className="social-icon">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.838579221183!2d72.88574191481082!3d19.28017355051737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b1be5fcbc5b1%3A0x3a629f3e19e59a7f!2sEcokleen%20Equipments%20Private%20Limited!5e0!3m2!1sen!2sin!4v1697037419169!5m2!1sen!2sin"
              width="100%"
              height="400"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
