import React, { useState } from "react";
import "../../../assets/style/HomeContact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import thumbnill from "../../../assets/images/Home/HomeMobileBanner/Thubnail.jpg";
function HomeContact() {
  const [showPopup, setShowPopup] = useState(false);

  const handleVideoPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="home-contact-section">
      <div className="container1 p-4">
        <div className="row align-items-center">
          <div className="col-lg-6 text-left">
            <h2 className="home-contact-title">
              Transform your cleaning routine with Wiggo Automation's
              state-of-the-art cleaning equipment.
            </h2>
            <p className="home-contact-description">
              Contact us today to learn more about our products and services.
            </p>
            <Link to="/contact" className="btn btn-warning mt-3">
              Contact Us
            </Link>
          </div>

          {/* YouTube Video Thumbnail */}
          <div className="col-lg-6 text-center mt-4 py-4">
            <div className="video-thumbnail-wrapper">
              <div className="youtube-thumbnail" onClick={handleVideoPopup}>
                <img
                  src={thumbnill}
                  alt="YouTube Thumbnail"
                  className="video-thumbnail"
                />
                <div className="custom-play-button">
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 103 103"
                    className="play-icon"
                  >
                    <defs>
                      <style>
                        {`
          .st0 { fill: #fbaf33; }
          .st1, .st2 { fill: none; stroke: #fbaf33; stroke-miterlimit: 10; }
          .st2 { stroke-width: 3px; }
          .st3 { fill: #e6e6e6; opacity: .8; }
        `}
                      </style>
                    </defs>
                    <circle className="st3" cx="51.5" cy="51.5" r="50.62" />
                    <circle className="st1" cx="51.5" cy="51.5" r="50.62" />
                    <circle className="st2" cx="51.5" cy="51.5" r="38.42" />
                    <polygon
                      className="st0"
                      points="41.19 37.5 41.19 65.5 63.81 51.5 41.19 37.5"
                    />
                  </svg>
                </div>
              </div>
            </div>

            {/* Popup Modal */}
            {showPopup && (
              <div className="video-popup-overlay" onClick={closePopup}>
                <div className="video-popup-content">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/2IGAtWnxm-g?autoplay=1"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                  <button className="close-button" onClick={closePopup}>
                    X
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeContact;
