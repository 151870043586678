import React from "react";
import Header from "../Components/Home Coomponents/Header/Header";
import Menu from "../Components/Home Coomponents/Menu/Menu";

function ThankYou() {
  return (
    <>
      <Header />
      <Menu />
    </>
  );
}

export default ThankYou;
