import React from "react";
import Header from "../Components/Home Coomponents/Header/Header";
import Menu from "../Components/Home Coomponents/Menu/Menu";
import Img1 from "../assets/images/Home/Home_banner1.png";
import Img2 from "../assets/images/Home/Home_banner2.jpg";
import HomeAbout from "../Components/Home Coomponents/HomeAbout/HomeAbout";
import HomeCount from "../Components/Home Coomponents/HomeCount/HomeCount";
import Client from "../Components/Home Coomponents/ClientList/Client";
import HomeContact from "../Components/Home Coomponents/HomeContact/HomeContact";
import HomeMap from "../Components/Home Coomponents/Map/HomeMap";
import Testimonial from "../Components/Home Coomponents/Testimonial/Testimonial";
import Footer from "../Components/Home Coomponents/Footer/Footer";
import TabCategoryDetail from "../Components/Home Coomponents/Category Wise Detail/TabCategoryDetail";
import { isMobile } from "react-device-detect";

// import Hbanner1 from "../assets/images/Home/HomeBanner/Banner-2.jpg";
// import Hbanner2 from "../assets/images/Home/HomeBanner/Banner-3.jpg";
// import Hbanner3 from "../assets/images/Home/HomeBanner/Home_banner.jpg";
import Hbanner1 from "../assets/images/Home/HomeBanner/Vacuum-Banner.jpg";
import Hbanner2 from "../assets/images/Home/HomeBanner/Scrubber-Banner.jpg";
import MobileBanner1 from '../assets/images/Home/HomeMobileBanner/Banner-2-mobile.jpg'
import MobileBanner2 from '../assets/images/Home/HomeMobileBanner/Banner-3-mobile.jpg'
import MobileBanner3 from '../assets/images/Home/HomeMobileBanner/Scrubber-Mobile-banner.jpg'

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";

function Home() {

  const desktopBanners = [Hbanner1, Hbanner2];
  const mobileBanners = [MobileBanner1, MobileBanner2, MobileBanner3];

  const bannersToUse = isMobile ? mobileBanners : desktopBanners;

  return (
    <>
      <div className="banner-container mb-4">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          speed={1000}
        >
          {bannersToUse.map((banner, index) => (
            <SwiperSlide key={index}>
              <img src={banner} alt={`Banner ${index + 1}`} className="slider-image" />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="overlay">
          <Header />
          <Menu />
        </div>
      </div>
      <HomeAbout />
      <TabCategoryDetail />
      <HomeCount />
      <Client />
      <HomeContact />
      <HomeMap />
      <Testimonial />
      <Footer />
    </>
  );
}

export default Home;
