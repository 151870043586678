import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from "./../../utils/firebase";
import emailjs from "emailjs-com";

const ContactModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    city: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.number) newErrors.number = "Number is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.message) newErrors.message = "Message is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await addDoc(collection(db, "contacts"), {
          name: formData.name,
          email: formData.email,
          number: formData.number,
          city: formData.city,
          message: formData.message,
        });

        const templateParams = {
          name: formData.name,
          email: formData.email,
          number: formData.number,
          city: formData.city,
          message: formData.message,
        };

        emailjs
          .send(
            "service_vxfpvdk",
            "template_fs2b8cg",
            templateParams,
            "yRfUhkNHId6ZFsbcA"
          )
          .then((response) => {
            console.log(
              "Email sent successfully:",
              response.status,
              response.text
            );
          })
          .catch((err) => {
            console.error("Error sending email:", err);
          });
        setFormData({ name: "", email: "", number: "", city: "", message: "" });
        handleClose();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      console.log("Form submitted:", formData);
      setFormData({ name: "", email: "", number: "", city: "", message: "" });
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formEmail" className="mt-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formNumber" className="mt-2">
            <Form.Label>Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your number"
              name="number"
              value={formData.number}
              onChange={handleChange}
              isInvalid={!!errors.number}
            />
            <Form.Control.Feedback type="invalid">
              {errors.number}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formCity" className="mt-2">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              isInvalid={!!errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {errors.city}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formMessage" className="mt-2">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter your message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              isInvalid={!!errors.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="warning" type="submit" className="mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
