// src/data/products.js
import p1Img from "../../../assets/Product Image/Plishing Machine/Wiggo_Heavy Duty Floor Polisher Machine.jpg";
import p2Img from "../../../assets/Product Image/Plishing Machine/Wiggo_Single disc Scubber Machine-Polisher Butterfly Body.jpg";
import p3Img from "../../../assets/Product Image/Plishing Machine/Wiggo_Single disc Scubber Machine-Polisher Metal Body.jpg";

import swb1 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Walk-Behind-Scrubber-Drier-Machine-WD-15C.jpg";
import swb2 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Walk-Behind-Scrubber-Drier-Machine-WD-25-C.jpg";
import swb3 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Walk-Behind-Scrubber-Drier-Machine-WD-30C.jpg";
import swb4 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Walk-Behind-Scrubber-Drier-Machine-WD-40C-&-WD-40B.jpg";
import swb5 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Heavy-Duty-Scrubber-Drier-Machine-WD-50C-&-WD-50B.jpg";
import swb6 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Heavy-Duty-Scrubber-Drier-Machine-WD-60.jpg";
import swb7 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Walk-Behind-Scrubber-Drier-Machine-WD-70.jpg";
import swb8 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Render-Walk-Behind-Scrubber-Drier-Machine-WD-70DB.jpg";

import wd70plus from "../../../assets/Product Image/Ride on Floor Scrubber/Wiggo_Ride on Floor Scrubber - WD-70+.jpg";
import wd80plus from "../../../assets/Product Image/Ride on Floor Scrubber/Wiggo_Ride on Floor Scrubber - WD-80+.jpg";
import wd80db from "../../../assets/Product Image/Ride on Floor Scrubber/Wiggo_Ride on Floor Scrubber - WD-80DB.jpg";
import wd90db from "../../../assets/Product Image/Ride on Floor Scrubber/Wiggo_Ride on Floor Scrubber - WD-90DB.jpg";
import wd120db from "../../../assets/Product Image/Ride on Floor Scrubber/Wiggo_Ride on Floor Scrubber - WD-120DB.jpg";
import wd180db from "../../../assets/Product Image/Ride on Floor Scrubber/Wiggo_Ride on Floor Scrubber - WD-180DB.jpg";

import ws1200 from "../../../assets/Product Image/Ride on Sweeper/Wiggo_Mini Ride On Sweeper WS 1200.jpg";
import ws1500 from "../../../assets/Product Image/Ride on Sweeper/Wiggo_Mini Ride On Sweeper WS-1500.jpg";
import ws1450 from "../../../assets/Product Image/Ride on Sweeper/Wiggo_RIDE ON SWEEPER WS-1450.jpg";
import wb2500 from "../../../assets/Product Image/Ride on Sweeper/Wiggo_WB 2500 CAR-RIDE-ON FLOOR-SWEEPER.jpg";
import wb3000 from "../../../assets/Product Image/Ride on Sweeper/Wiggo_WB 3000 CAR RIDE ON FLOOR SWEEPER.jpg";
import ws2000a from "../../../assets/Product Image/Ride on Sweeper/Wiggo_WS2000A.jpg";

import ws45l from "../../../assets/Product Image/Walk Behind Sweeper/Wiggo_Electric WALK BEHIND SWEEPER WS-45L.jpg";
import ws920 from "../../../assets/Product Image/Walk Behind Sweeper/Wiggo_MaNUAL SWEEPER  MACHINE WS-920.jpg";

import cc from "../../../assets/Product Image/Three-in-one-automatic-carpat-cleaning-machine/Wiggo_Three-in-one-automatic-carpat-cleaning-machine.jpg";

import p1Img1 from "../../../assets/Product Image/Plishing Machine/Image/Wiggo_Heavy Duty Floor Polisher Machine.jpg";
import p2Img1 from "../../../assets/Product Image/Plishing Machine/Image/Wiggo_Single disc Scubber Machine-Polisher Butterfly Body.jpg";
import p3Img1 from "../../../assets/Product Image/Plishing Machine/Image/Wiggo_Single disc Scubber Machine-Polisher Metal Body.jpg";

import swb11 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Walk-Behind-Scrubber-Drier-Machine-WD-15C.jpg";
import swb21 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Walk-Behind-Scrubber-Drier-Machine-WD-25-C.jpg";
import swb31 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-30C.jpg";
import swb41 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Walk-Behind-Scrubber-Drier-Machine-WD-40C-&-WD-40B.jpg";
import swb51 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Heavy-Duty-Scrubber-Drier-Machine-WD-50C-&-WD-50B.jpg";
import swb61 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Heavy-Duty-Scrubber-Drier-Machine-WD-60.jpg";
import swb71 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Walk-Behind-Scrubber-Drier-Machine-WD-70.jpg";
import swb81 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/Walk-Behind-Scrubber-Drier-Machine-WD-70DB.jpg";

import wd70plus1 from "../../../assets/Product Image/Ride on Floor Scrubber/Image/Wiggo_Ride on Floor Scrubber - WD-70+.jpg";
import wd80plus1 from "../../../assets/Product Image/Ride on Floor Scrubber/Image/Wiggo_Ride on Floor Scrubber - WD-80+.jpg";
import wd80db1 from "../../../assets/Product Image/Ride on Floor Scrubber/Image/Wiggo_Ride on Floor Scrubber - WD-80DB.jpg";
import wd90db1 from "../../../assets/Product Image/Ride on Floor Scrubber/Image/Wiggo_Ride on Floor Scrubber - WD-90DB.jpg";
import wd120db1 from "../../../assets/Product Image/Ride on Floor Scrubber/Image/Wiggo_Ride on Floor Scrubber - WD-120DB.jpg";
import wd180db1 from "../../../assets/Product Image/Ride on Floor Scrubber/Image/Wiggo_Ride on Floor Scrubber - WD-180DB.jpg";

import ws12001 from "../../../assets/Product Image/Ride on Sweeper/Image/Wiggo_Mini Ride On Sweeper WS 1200.jpg";
import ws15001 from "../../../assets/Product Image/Ride on Sweeper/Image/Wiggo_Mini Ride On Sweeper WS-1500.jpg";
import ws14501 from "../../../assets/Product Image/Ride on Sweeper/Image/Wiggo_RIDE ON SWEEPER WS-1450.jpg";
import wb25001 from "../../../assets/Product Image/Ride on Sweeper/Image/Wiggo_WB 2500 CAR-RIDE-ON FLOOR-SWEEPER.jpg";
import wb30001 from "../../../assets/Product Image/Ride on Sweeper/Image/Wiggo_WB 3000 CAR RIDE ON FLOOR SWEEPER.jpg";
import ws2000a1 from "../../../assets/Product Image/Ride on Sweeper/Image/Wiggo_WS2000A.jpg";

import ws45l1 from "../../../assets/Product Image/Walk Behind Sweeper/Image/Wiggo_Electric WALK BEHIND SWEEPER WS-45L.jpg";
import ws9201 from "../../../assets/Product Image/Walk Behind Sweeper/Image/Wiggo_MaNUAL SWEEPER  MACHINE WS-920.jpg";

import cc1 from "../../../assets/Product Image/Three-in-one-automatic-carpat-cleaning-machine/Image/Wiggo_Three-in-one-automatic-carpat-cleaning-machine.jpg";

import v1 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR ULTIMO-15.jpg";
import v2 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR ULTIMO-30.jpg";
import v3 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR ULTIMO-60.jpg";
import v4 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR ULTIMO-80.jpg";
import v5 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR WC-20.jpg";
import v6 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR WC-20SF.jpg";
import v7 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR WC-40.jpg";
import v8 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/WEB AND DRY VACUUM CLENERR WC-40SF.jpg";

import pdfFile from "../../../assets/Product Image/Plishing Machine/Heavy Duty Floor Polisher Machin.pdf";
import pdfFile2 from "../../../assets/Product Image/Plishing Machine/Single Disc Scrubber Machine (Polisher Butterfly Body).pdf";
import pdfFile3 from "../../../assets/Product Image/Plishing Machine/Single Disc Scrubber Machine (Polisher Metal Body).pdf";
import pdfFile4 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-15C.pdf";
import pdfFile5 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-25C.pdf";
import pdfFile6 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-30C.pdf";
import pdfFile7 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-40C and WD-40B.pdf";
import pdfFile8 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-50C and WD-50B.pdf";
import pdfFile9 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-60.pdf";
import pdfFile10 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-70.pdf";
import pdfFile11 from "../../../assets/Product Image/Ride on Floor Scrubber/WD-70+.pdf";
import pdfFile12 from "../../../assets/Product Image/Ride on Floor Scrubber/WD-80+.pdf";
import pdfFile13 from "../../../assets/Product Image/Ride on Floor Scrubber/WD-80DB.pdf";
import pdfFile14 from "../../../assets/Product Image/Ride on Floor Scrubber/WD-90DB.pdf";
import pdfFile15 from "../../../assets/Product Image/Ride on Floor Scrubber/WD-120DB.pdf";
import pdfFile16 from "../../../assets/Product Image/Ride on Floor Scrubber/WD-180DB.pdf";
import pdfFile17 from "../../../assets/Product Image/Ride on Sweeper/WS-1200.pdf";
import pdfFile18 from "../../../assets/Product Image/Ride on Sweeper/WS-1500.pdf";
import pdfFile19 from "../../../assets/Product Image/Ride on Sweeper/WS-1450.pdf";
import pdfFile20 from "../../../assets/Product Image/Ride on Sweeper/WB-2500 Car Ride on Floor Sweeper.pdf";
import pdfFile21 from "../../../assets/Product Image/Ride on Sweeper/WB-3000 Car Ride On Floor Sweeper.pdf";
import pdfFile22 from "../../../assets/Product Image/Ride on Sweeper/WS2000A.pdf";
import pdfFile23 from "../../../assets/Product Image/Walk Behind Sweeper/WS-45L.pdf";
import pdfFile24 from "../../../assets/Product Image/Walk Behind Sweeper/WS-920.pdf";
import pdfFile25 from "../../../assets/Product Image/Three-in-one-automatic-carpat-cleaning-machine/3 in 1 Automatic Carpet Cleaning Machin.pdf";
import pdfFile26 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/Wet and Dry Vaccum Cleaner.pdf";
import pdfFile27 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/2 IN 1 Upholstery Cleaning Machine WC-20-WC-40.pdf";
import pdfFile28 from "../../../assets/Product Image/Wet and Dry Vacuum Cleaners/3 IN 1 Upholstery Cleaning Machine WC-20 SF-WC-40SF.pdf";
import pdfFile34 from "../../../assets/Product Image/Walk Behind Floor Scrrubber/WD-70DB.pdf"

const ProductDetailList = [
  {
    id: 1,
    productName: "Heavy Duty Floor Polisher Machine",
    image: p1Img,
    imageg: p1Img1,
    description: `
      <p>The Polishing Machine is a specialized tool designed to bring out the shine in your floors, making them look as good as new. This machine is ideal for use in a variety of environments, including offices, temples, and other commercial settings where maintaining a high standard of cleanliness and presentation is essential. With its powerful motor and user-friendly design, the Polishing Machine ensures that your floors are always gleaming, leaving a lasting impression on visitors and clients alike.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>High-Performance Polishing:</strong> Delivers a brilliant shine, revitalizing dull and worn floors.</li>
        <li><strong>Versatile Application:</strong> Suitable for use in offices, temples, and other commercial spaces, ensuring floors always look their best.</li>
        <li><strong>User-Friendly Operation:</strong> Simple to use, requiring minimal training, making it accessible for all cleaning staff.</li>
        <li><strong>Efficient Design:</strong> Saves time and effort, allowing for quick and effective polishing of large areas.</li>
      </ul>
      <p>Invest in the Polishing Machine to maintain the beauty and cleanliness of your floors, ensuring they always make a positive impression.</p>
    `,
    pdf: pdfFile,
  },
  {
    id: 2,
    productName: "Single Disc Scrubber Machine (Polisher Butterfly Body)",
    image: p2Img,
    imageg: p2Img1,
    description: `
      <p>The Single Disc Scrubber Machine/Polisher - Butterfly Body is an elegant and efficient solution for maintaining clean and polished floors in offices and temples. This machine comes equipped with essential accessories, including a soft brush, hard brush, and pad brush, making it versatile enough to handle various floor types and cleaning tasks. Its butterfly body design not only enhances its aesthetic appeal but also ensures ease of use and maneuverability in tight spaces.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Versatile Accessories:</strong> Includes a soft brush, hard brush, and pad brush, allowing for tailored cleaning and polishing on different floor types.</li>
        <li><strong>Elegant Design:</strong> The butterfly body design provides both style and functionality, making it easy to maneuver and operate.</li>
        <li><strong>Efficient Cleaning:</strong> Ideal for maintaining cleanliness in offices and temples, ensuring floors are always polished to perfection.</li>
        <li><strong>User-Friendly Operation:</strong> Designed for ease of use, requiring minimal training for non-professional employees.</li>
      </ul>
      <p>The Single Disc Scrubber Machine/Polisher - Butterfly Body is the perfect addition to any cleaning arsenal, providing a reliable and stylish solution for maintaining spotless floors in professional and religious settings.</p>
    `,
    pdf: pdfFile2,
  },
  {
    id: 3,
    productName: "Single Disc Scrubber Machine (Polisher Metal Body)",
    image: p3Img,
    imageg: p3Img1,
    description: `
    <p>The Single Disc Scrubber Machine/Polisher - Metal Body is a robust and durable cleaning machine designed for demanding environments like offices and temples. This machine comes with essential accessories, including a soft brush, hard brush, and pad brush, making it adaptable to various floor types and cleaning tasks. The metal body construction ensures long-lasting durability and reliable performance, even in heavy-duty applications.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Durable Metal Body:</strong> Built to withstand rigorous use, ensuring long-lasting performance in demanding environments.</li>
      <li><strong>Versatile Accessories:</strong> Includes a soft brush, hard brush, and pad brush for effective cleaning and polishing on different floor surfaces.</li>
      <li><strong>Heavy-Duty Performance:</strong> Ideal for maintaining cleanliness in offices and temples, ensuring floors are polished and pristine.</li>
      <li><strong>Easy to Operate:</strong> Designed with user-friendliness in mind, allowing for quick and efficient cleaning with minimal training.</li>
    </ul>
    <p>The Single Disc Scrubber Machine/Polisher - Metal Body is the go-to solution for heavy-duty floor cleaning and polishing, offering unmatched durability and performance in professional and religious settings.</p>
  `,
    pdf: pdfFile3,
  },
  {
    id: 4,
    productName: "Walk Behind Scrubber Drier Machine - WD-15C",
    image: swb1,
    imageg: swb11,
    description: `
    <p>The Scrubber Drier Machine - WD-15C is a practical and efficient cleaning solution designed to simplify floor maintenance in commercial environments. This machine combines scrubbing and drying functions in one go, ensuring that floor dirt is thoroughly removed and surfaces are left dry and safe to walk on. The WD-15C is ideal for use in cafeterias, offices, hotel rooms, and showrooms, providing a cost-effective alternative to manual cleaning.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Practical and Cost-Saving:</strong> Replaces multiple cleaning personnel, reducing labor costs and maintenance expenses.</li>
      <li><strong>Quick and Efficient:</strong> Scrubbing and drying are completed in one go, ensuring thorough cleaning in a single pass.</li>
      <li><strong>User-Friendly Design:</strong> Easy to operate and maintain, even for non-professional employees.</li>
      <li><strong>Solenoid Valve Control:</strong> Ensures precise water output control, preventing equipment damage and reducing the need for costly replacements.</li>
    </ul>
    <p>The Scrubber Drier Machine - WD-15C is an essential tool for maintaining clean and dry floors in busy commercial spaces, offering a reliable and efficient solution for floor maintenance.</p>
  `,
    pdf: pdfFile4,
  },
  {
    id: 5,
    productName: "Walk-Behind-Scrubber-Drier-Machine-WD-25-C",
    image: swb2,
    imageg: swb21,
    description: `
    <p>The Scrubber Drier Machine - WD-25C is a practical and efficient cleaning solution designed to streamline floor maintenance in various commercial environments. With the ability to scrub and dry floors simultaneously, the WD-25C ensures thorough cleaning in a single pass, leaving floors spotless and dry. This machine is particularly well-suited for use in offices, hospitals, hotels, clubhouses, and other settings where maintaining clean and safe floors is crucial.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Practical and Cost-Saving:</strong> The WD-25C is a cost-effective solution that can replace the need for multiple cleaning staff, significantly reducing labor costs and maintenance expenses.</li>
      <li><strong>Quick and Convenient:</strong> Scrubbing and drying are completed in one go, ensuring efficient cleaning and minimizing downtime. The floor dirt is effectively removed in a single pass, making it perfect for high-traffic areas.</li>
      <li><strong>User-Friendly Operation:</strong> Non-professional employees can easily operate and maintain the WD-25C, thanks to its intuitive design and straightforward controls.</li>
      <li><strong>Solenoid Valve Water Control:</strong> The solenoid valve precisely controls water output, reducing the risk of equipment damage and the need for frequent maintenance by preventing issues caused by over-wetting or frequent manual adjustments.</li>
    </ul>
    <p>The Scrubber Drier Machine - WD-25C is an ideal choice for any commercial setting that requires efficient and reliable floor cleaning. Whether in an office, hospital, hotel, or clubhouse, this machine ensures that your floors are consistently clean, dry, and safe for all users. Investing in the WD-25C means investing in a cleaner, more efficient working environment.</p>
  `,
    pdf: pdfFile5,
  },
  {
    id: 6,
    productName: "WD-30C",
    image: swb3,
    imageg: swb31,
    description: `
    <p>The Walk Behind Scrubber Drier Machine WD-30C is the epitome of practical and cost-saving floor cleaning solutions. This robust industrial floor cleaning machine is designed to efficiently replace multiple cleaning personnel, significantly reducing labor costs and maintenance expenses. Engineered for quick and convenient operation, the WD-30C completes scrubbing and drying tasks in a single pass, ensuring thorough removal of floor dirt and leaving surfaces impeccably clean.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Practical and Cost-Saving:</strong> The WD-30C replaces multiple cleaning personnel, cutting down on labor costs and maintenance expenses.</li>
      <li><strong>Quick and Convenient:</strong> Capable of scrubbing and drying in one go, ensuring efficient and effective cleaning.</li>
      <li><strong>User-Friendly Design:</strong> Easy operation and maintenance by non-professional employees, making it a versatile tool for various cleaning environments.</li>
      <li><strong>Solenoid Valve Control:</strong> This feature ensures precise water output control, preventing frequent operation of the water valve switch and reducing the risk of equipment damage.</li>
    </ul>
    <p>Ideal for commercial settings such as cafeterias, offices, hotel rooms, and showrooms, the WD-30C's versatility and efficiency make it a must-have for maintaining cleanliness in high-traffic areas. Its user-friendly design allows non-professional employees to operate and maintain it with ease, ensuring consistent performance without the need for specialized training.</p>
    <p>The solenoid valve, which controls water output, is a standout feature of the WD-30C. This advanced mechanism prevents the frequent operation of the water valve switch, reducing the risk of equipment damage and the need for costly replacements. The result is a durable and reliable machine that consistently performs at its best.</p>
    <p>Upgrade your cleaning routine with the Walk Behind Scrubber Drier Machine WD-30C, a reliable and efficient solution for maintaining pristine floors in any commercial setting.</p>
  `,
    pdf: pdfFile6,
  },
  {
    id: 7,
    productName: "Walk-Behind-Scrubber-Drier-Machine-WD-40C-&-WD-40B",
    image: swb4,
    imageg: swb41,
    description: `
      <p>The Walk Behind Scrubber Drier Machine WD-40C | WD-40B is designed for unparalleled efficiency and convenience in floor cleaning. This powerful industrial floor cleaning machine is capable of handling heavy-duty tasks, effectively replacing multiple cleaning personnel and significantly reducing labor costs and maintenance expenses.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Heavy-Duty Performance:</strong> Capable of handling rigorous cleaning tasks, making it ideal for a variety of commercial environments.</li>
        <li><strong>Cost-Effective Solution:</strong> Reduces the need for multiple cleaning personnel, cutting down on labor costs and maintenance expenses.</li>
        <li><strong>Efficient Cleaning:</strong> Scrubbing and drying are completed in one go, ensuring thorough removal of floor dirt.</li>
        <li><strong>User-Friendly Design:</strong> Non-professional employees can easily operate and maintain it, ensuring consistent performance.</li>
        <li><strong>Solenoid Valve Control:</strong> Ensures precise water output control, preventing equipment damage and minimizing replacement costs.</li>
      </ul>
      <p>Ideal for use in cafeterias, offices, hotel rooms, and showrooms, the WD-40C | WD-40B's powerful scrubbing and drying capabilities ensure that all floor dirt is effectively removed in a single pass. Its user-friendly design allows non-professional employees to operate and maintain it with ease, ensuring consistent performance without the need for specialized training.</p>
      <p>The solenoid valve, which controls water output, is a standout feature of the WD-40C | WD-40B. This advanced mechanism prevents the frequent operation of the water valve switch, reducing the risk of equipment damage and the need for costly replacements. The result is a durable and reliable machine that consistently performs at its best.</p>
      <p>Invest in the Walk Behind Scrubber Drier Machine WD-40C | WD-40B for a reliable, efficient, and cost-effective cleaning solution that enhances your cleaning operations and maintains pristine floors in any commercial environment.</p>
    `,
    pdf: pdfFile7,
  },
  {
    id: 8,
    productName: "Heavy-Duty-Scrubber-Drier-Machine-WD-50C-&-WD-50B",
    image: swb5,
    imageg: swb51,
    description: `
      <p>The Heavy Duty Scrubber Drier Machine WD-50C | WD-50B is engineered for maximum performance and durability. Designed to tackle the most demanding cleaning tasks, this industrial floor cleaning machine features a powerful motor, large water tank, humanized control, and long battery life, making it an indispensable tool for rigorous cleaning operations.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Powerful Motor:</strong> Provides robust cleaning power, capable of handling heavy-duty tasks with ease.</li>
        <li><strong>Large Water Tank:</strong> Ensures extended cleaning sessions without the need for frequent refills.</li>
        <li><strong>Humanized Control:</strong> User-friendly interface allows for easy operation and maintenance by non-professional employees.</li>
        <li><strong>Long Battery Life:</strong> Enables prolonged usage, reducing downtime and increasing efficiency.</li>
      </ul>
      <p>Ideal for use in commercial environments such as cafeterias, offices, hotel rooms, and showrooms, the WD-50C | WD-50B ensures thorough cleaning in a single pass. Its powerful scrubbing and drying capabilities effectively replace multiple cleaning personnel, significantly cutting down on labor costs and maintenance expenses.</p>
      <p>The solenoid valve, which controls water output, is a standout feature of the WD-50C | WD-50B. This advanced mechanism prevents the frequent operation of the water valve switch, reducing the risk of equipment damage and the need for costly replacements. The result is a durable and reliable machine that consistently performs at its best.</p>
      <p>Enhance your cleaning operations with the Heavy Duty Scrubber Drier Machine WD-50C | WD-50B, a reliable, efficient, and cost-effective solution for maintaining pristine floors in any commercial setting.</p>
    `,
    pdf: pdfFile8,
  },
  // {
  //   id: 9,
  //   productName: "Heavy-Duty-Scrubber-Drier-Machine-WD-60",
  //   image: swb6,
  //   imageg: swb61,
  //   description: `
  //     <p>Introducing the Heavy Duty Scrubber Drier Machine WD-60, designed for robust performance and long-lasting durability. This powerful industrial floor cleaning machine is equipped with a powerful motor, large water tank, humanized control, and long battery life, making it the perfect choice for the most demanding cleaning tasks.</p>
  //     <p><strong>Key Features:</strong></p>
  //     <ul>
  //       <li><strong>Powerful Motor:</strong> Delivers exceptional cleaning power for handling tough cleaning jobs.</li>
  //       <li><strong>Large Water Tank:</strong> Allows for extended cleaning sessions without the need for frequent refills.</li>
  //       <li><strong>Humanized Control:</strong> Easy-to-use interface ensures effortless operation and maintenance by non-professional employees.</li>
  //       <li><strong>Long Battery Life:</strong> Provides prolonged usage, minimizing downtime and maximizing efficiency.</li>
  //     </ul>
  //     <p>Ideal for commercial settings such as cafeterias, offices, hotel rooms, and showrooms, the WD-60 ensures thorough cleaning in a single pass. Its powerful scrubbing and drying capabilities effectively replace multiple cleaning personnel, reducing labor costs and maintenance expenses.</p>
  //     <p>The solenoid valve, which controls water output, is a standout feature of the WD-60. This advanced mechanism prevents the frequent operation of the water valve switch, reducing the risk of equipment damage and the need for costly replacements. The result is a durable and reliable machine that consistently performs at its best.</p>
  //     <p>Upgrade your cleaning operations with the Heavy Duty Scrubber Drier Machine WD-60, a reliable, efficient, and cost-effective solution for maintaining pristine floors in any commercial environment.</p>
  //   `,
  //   pdf: pdfFile9,
  // },
  {
    id: 10,
    productName: "WD-70",
    image: swb7,
    imageg: swb71,
    description: `
    <p>The Walk Behind Scrubber Drier Machine WD-70 is the ultimate solution for efficient and cost-effective floor cleaning. This powerful industrial floor cleaning machine features a powerful motor, large water tank, humanized control, and long battery life, ensuring exceptional performance and durability.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Powerful Motor:</strong> Provides robust cleaning power for handling tough cleaning jobs.</li>
      <li><strong>Large Water Tank:</strong> Allows for extended cleaning sessions without the need for frequent refills.</li>
      <li><strong>Humanized Control:</strong> Easy-to-use interface ensures effortless operation and maintenance by non-professional employees.</li>
      <li><strong>Long Battery Life:</strong> Provides prolonged usage, minimizing downtime and maximizing efficiency.</li>
    </ul>
    <p>Ideal for commercial settings such as cafeterias, offices, hotel rooms, and showrooms, the WD-70 ensures thorough cleaning in a single pass. Its powerful scrubbing and drying capabilities effectively replace multiple cleaning personnel, reducing labor costs and maintenance expenses.</p>
    <p>The solenoid valve, which controls water output, is a standout feature of the WD-70. This advanced mechanism prevents the frequent operation of the water valve switch, reducing the risk of equipment damage and the need for costly replacements. The result is a durable and reliable machine that consistently performs at its best.</p>
    <p>Invest in the Walk Behind Scrubber Drier Machine WD-70 for a reliable, efficient, and cost-effective cleaning solution that enhances your cleaning operations and maintains pristine floors in any commercial environment.</p>
  `,
    pdf: pdfFile10,
  },
  // {
  //   id: 11,
  //   productName: "Ride on Floor Scrubber - WD-70+",
  //   image: wd70plus,
  //   imageg: wd70plus1,
  //   description: `
  //   <p>The Ride-On Floor Scrubber WD 70+ is designed for versatility and efficiency. This machine is perfect for medium to large areas, offering superior cleaning performance with its powerful motor and ergonomic design. The WD 70+ is ideal for maintaining cleanliness in shopping malls, office buildings, and industrial spaces.</p>
  //   <p><strong>Key Features:</strong></p>
  //   <ul>
  //     <li><strong>Compact Design:</strong> For easy maneuverability.</li>
  //     <li><strong>Powerful Cleaning Action:</strong> Effective on all floor types.</li>
  //     <li><strong>User-Friendly Controls:</strong> Ensures effortless operation.</li>
  //     <li><strong>Versatile Application:</strong> Suitable for both commercial and industrial uses.</li>
  //   </ul>
  //   <p><strong>Keywords:</strong> ride-on floor scrubber, commercial floor scrubber, industrial floor cleaning machine, office floor scrubber.</p>
  // `,
  //   pdf: pdfFile11,
  // },
  {
    id: 34,
    productName: "Havy Duty Scrubber Driver WD-70DB",
    image: swb8,
    imageg: swb81,
    description: `<p>The Walk Behind Scrubber Drier Machine WD-70DB is a double brush scrubber drier designed to enhance cleaning efficiency. This powerful industrial floor cleaning machine features a robust motor, a large water tank, humanized controls, and long battery life, making it the ideal solution for demanding cleaning tasks in commercial environments.</p>

<p><strong>Key Features:</strong></p>
<ul>
  <li><strong>Double Brush Design:</strong> Ensures superior cleaning performance, tackling tough dirt and grime with ease.</li>
  <li><strong>Powerful Motor:</strong> Provides exceptional cleaning power for handling challenging cleaning jobs.</li>
  <li><strong>Large Water Tank:</strong> Extends cleaning sessions without frequent refills, enhancing productivity.</li>
  <li><strong>Humanized Controls:</strong> Simple, user-friendly interface for effortless operation and maintenance by non-professional users.</li>
  <li><strong>Long Battery Life:</strong> Reduces downtime and maximizes efficiency with extended usage.</li>
</ul>

<p>The WD-70DB is ideal for various commercial settings such as cafeterias, offices, hotel rooms, and showrooms. Its scrubbing and drying capabilities in a single pass effectively reduce labor costs by replacing multiple cleaning personnel, contributing to lower maintenance expenses.</p>

<p>A standout feature of the WD-70DB is its <strong>solenoid valve</strong>, which controls water output to prevent excessive use of the water valve switch, reducing wear and tear. This ensures durability and long-lasting performance.</p>

<p><strong>Keywords:</strong> walk-behind scrubber drier, industrial scrubber, commercial floor cleaning machine, double brush floor scrubber.</p>
`,
pdf: pdfFile34,
  },
  // {
  //   id: 12,
  //   productName: "Ride on Floor Scrubber - WD-80+",
  //   image: wd80plus,
  //   imageg: wd80plus1,
  //   description: `
  //   <p>The Ride-On Floor Scrubber WD 80+ combines power and efficiency in a compact design, making it the perfect choice for medium-sized commercial spaces. Its advanced scrubbing mechanism ensures that floors are left spotless and dry, while its user-friendly interface allows for easy operation by non-professional staff.</p>
  //   <p><strong>Key Features:</strong></p>
  //   <ul>
  //     <li><strong>Efficient Water Management System:</strong> Ensures optimal cleaning results.</li>
  //     <li><strong>Compact and Durable Design:</strong> For easy storage and long-lasting use.</li>
  //     <li><strong>Simple Controls:</strong> Enables hassle-free operation.</li>
  //     <li><strong>Versatile Application:</strong> Ideal for office buildings, retail spaces, and industrial environments.</li>
  //   </ul>
  //   <p><strong>Keywords:</strong> ride-on floor scrubber, commercial floor scrubber, industrial floor cleaning machine, compact floor scrubber.</p>
  // `,
  //   pdf: pdfFile12,
  // },
  {
    id: 13,
    productName: "Ride on Floor Scrubber - WD-80DB",
    image: wd80db,
    imageg: wd80db1,
    description: `
      <p>The Ride-On Floor Scrubber WD 80DB is a robust and reliable cleaning solution for large-scale commercial and industrial spaces. Designed to handle extensive areas with ease, this ride-on floor scrubber is perfect for environments like warehouses, shopping malls, and airports. Its powerful motor ensures deep cleaning, while the ergonomic design allows operators to navigate tight spaces effortlessly.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Heavy-Duty Performance:</strong> Suitable for industrial use.</li>
        <li><strong>Advanced Water Control System:</strong> Ensures efficient cleaning.</li>
        <li><strong>User-Friendly Controls:</strong> Allows for easy operation.</li>
        <li><strong>Long Battery Life:</strong> Supports extended cleaning sessions.</li>
      </ul>
      <p><strong>Keywords:</strong> ride-on floor scrubber, industrial floor cleaning machine, commercial floor scrubber, warehouse floor cleaning machine.</p>
    `,
    pdf: pdfFile13,
  },
  {
    id: 14,
    productName: "Ride on Floor Scrubber - WD-90DB",
    image: wd90db,
    imageg: wd90db1,
    description: `
      <p>The Ride-On Floor Scrubber WD 90DB offers unparalleled cleaning performance for high-traffic areas. This industrial floor scrubber is equipped with a powerful motor and a large water tank, making it ideal for heavy-duty applications. Its durable design ensures long-lasting use, while the efficient scrubbing mechanism delivers pristine floors in a single pass.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Large Water Tank:</strong> Allows for extended cleaning without frequent refills.</li>
        <li><strong>Powerful Scrubbing Mechanism:</strong> Ensures deep cleaning.</li>
        <li><strong>User-Friendly Interface:</strong> Features easy-to-use controls.</li>
        <li><strong>Ideal for Large Commercial and Industrial Spaces:</strong> Perfect for high-traffic areas.</li>
      </ul>
      <p><strong>Keywords:</strong> industrial floor scrubber, ride-on floor scrubber, heavy-duty floor scrubber, commercial floor cleaning machines.</p>
    `,
    pdf: pdfFile14,
  },
  {
    id: 15,
    productName: "Ride on Floor Scrubber - WD-120DB",
    image: wd120db,
    imageg: wd120db1,
    description: `
      <p>The Ride-On Floor Scrubber WD 120DB is engineered for maximum efficiency and performance. With its robust build and advanced cleaning technology, this commercial floor scrubber is perfect for maintaining cleanliness in large spaces such as airports, factories, and shopping centers. The WD 120DB’s ergonomic design ensures comfort and ease of use for operators, even during prolonged cleaning sessions.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>High-Efficiency Motor:</strong> Provides superior cleaning power.</li>
        <li><strong>Large-Capacity Water Tank:</strong> Allows for uninterrupted cleaning.</li>
        <li><strong>Comfortable Seating and Controls:</strong> Designed for operator convenience.</li>
        <li><strong>Ideal for Extensive Floor Areas:</strong> Suitable for industrial applications.</li>
      </ul>
      <p><strong>Keywords:</strong> commercial floor scrubber, ride-on floor scrubber, industrial floor cleaning machine, warehouse floor scrubber.</p>
    `,
    pdf: pdfFile15,
  },
  {
    id: 16,
    productName: "Ride on Floor Scrubber - WD-180DB",
    image: wd180db,
    imageg: wd180db1,
    description: `
    <p>The Ride-On Floor Scrubber WD 180DB is a top-of-the-line cleaning machine designed for the toughest industrial environments. This ride-on scrubber dryer is equipped with the latest technology to ensure thorough and efficient cleaning. Its heavy-duty construction and large battery capacity make it ideal for continuous use in large facilities.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Heavy-Duty Motor:</strong> Designed for industrial-grade cleaning.</li>
      <li><strong>Extended Battery Life:</strong> Ensures uninterrupted operation.</li>
      <li><strong>Advanced Control Panel:</strong> For easy management and operation.</li>
      <li><strong>Perfect for Large Facilities:</strong> Ideal for warehouses, factories, and commercial spaces.</li>
    </ul>
    <p><strong>Keywords:</strong> ride-on floor scrubber, industrial floor scrubber, commercial floor scrubber, heavy-duty floor scrubber.</p>
  `,
    pdf: pdfFile16,
  },
  {
    id: 17,
    productName: "Mini Ride On Sweeper WS 1200",
    image: ws1200,
    imageg: ws12001,
    description: `
      <p>The Mini Ride-On Sweeper WS 1200 offers powerful sweeping in a compact, ride-on design. Ideal for both indoor and outdoor use, this ride-on sweeper is perfect for cleaning parking lots, warehouses, and large commercial spaces. Its compact size allows it to navigate tight spaces while providing powerful cleaning performance.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Compact Ride-On Design:</strong> Engineered for easy navigation in tight spaces.</li>
        <li><strong>Powerful Sweeping Mechanism:</strong> Ensures effective cleaning performance.</li>
        <li><strong>Easy-to-Use Controls:</strong> Designed for effortless operation.</li>
        <li><strong>Versatile Use:</strong> Suitable for both indoor and outdoor cleaning tasks.</li>
      </ul>
      <p><strong>Keywords:</strong> mini ride-on sweeper, ride-on floor sweeper, industrial sweeper, commercial floor cleaning machine.</p>
    `,
    pdf: pdfFile17,
  },
  {
    id: 18,
    productName: "Mini Ride On Sweeper WS-1500",
    image: ws1500,
    imageg: ws15001,
    description: `
    <p>The Mini Ride-On Sweeper WS-1500 is a versatile and compact cleaning machine designed for smaller industrial and commercial spaces. This ride-on sweeper offers powerful sweeping in a small package, making it ideal for navigating tight areas while still delivering exceptional cleaning performance. Perfect for facilities like office buildings, retail spaces, and small warehouses.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Compact Design:</strong> Ensures easy maneuverability in confined spaces.</li>
      <li><strong>Powerful Sweeping Action:</strong> Effective on all floor types.</li>
      <li><strong>Easy-to-Use Controls:</strong> Designed for non-professional operators.</li>
      <li><strong>Versatile Use:</strong> Suitable for small to medium-sized commercial spaces.</li>
    </ul>
    <p><strong>Keywords:</strong> mini ride-on sweeper, ride-on floor sweeper, industrial sweeper, commercial floor cleaning machine.</p>
  `,
    pdf: pdfFile18,
  },
  {
    id: 19,
    productName: "RIDE ON SWEEPER WS-1450 WS-1450",
    image: ws1450,
    imageg: ws14501,
    description: `
      <p>The Ride-On Sweeper WS-1450 is designed for heavy-duty sweeping in large commercial and industrial spaces. This industrial sweeper is equipped with a powerful motor and a large dustbin, making it ideal for environments like warehouses, parking lots, and factories. The WS-1450 delivers exceptional cleaning performance with its wide sweeping path and robust construction.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Wide Sweeping Path:</strong> Ensures maximum coverage for efficient cleaning.</li>
        <li><strong>Heavy-Duty Motor:</strong> Built for industrial applications, providing reliable performance.</li>
        <li><strong>Large Dustbin:</strong> Offers extended cleaning capacity, reducing downtime for emptying.</li>
        <li><strong>Ideal Use:</strong> Perfect for large commercial and industrial spaces.</li>
      </ul>
      <p><strong>Keywords:</strong> ride-on sweeper, industrial sweeper, commercial floor cleaning machine, warehouse floor sweeper.</p>
    `,
    pdf: pdfFile19,
  },
  {
    id: 20,
    productName: "WB 2500 Car Ride On Floor Sweeper",
    image: wb2500,
    imageg: wb25001,
    description: `
      <p>The Ride-On Floor Sweeper WB 2500 is a heavy-duty cleaning machine designed to sweep large areas efficiently. This ride-on sweeper is ideal for industrial settings such as warehouses, parking lots, and large commercial facilities. Its powerful motor and large dustbin ensure that extensive areas are cleaned quickly and effectively.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Powerful Motor:</strong> Ensures effective sweeping for optimal cleanliness.</li>
        <li><strong>Large Dustbin:</strong> Allows for extended cleaning capacity, minimizing downtime.</li>
        <li><strong>Heavy-Duty Construction:</strong> Built for industrial use, ensuring durability and reliability.</li>
        <li><strong>Ideal Use:</strong> Perfect for large industrial and commercial spaces.</li>
      </ul>
      <p><strong>Keywords:</strong> ride-on floor sweeper, industrial sweeper, commercial floor cleaning machine, warehouse floor sweeper.</p>
    `,
    pdf: pdfFile20,
  },
  {
    id: 21,
    productName: "WB 3000 Car Ride On Floor Sweeper",
    image: wb3000,
    imageg: wb30001,
    description: `
      <p>The Ride-On Floor Sweeper WB 3000 is a top-of-the-line sweeping machine designed for the most demanding industrial applications. This ride-on sweeper is equipped with a powerful motor, a wide sweeping path, and a durable design, making it the perfect choice for large-scale facilities such as warehouses, factories, and parking lots.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Wide Sweeping Path:</strong> Ensures maximum efficiency for faster cleaning.</li>
        <li><strong>Heavy-Duty Motor:</strong> Provides industrial-grade performance for rigorous tasks.</li>
        <li><strong>Robust Design:</strong> Built for long-lasting durability, suitable for tough environments.</li>
        <li><strong>Ideal Use:</strong> Perfect for large industrial and commercial spaces.</li>
      </ul>
      <p><strong>Keywords:</strong> ride-on floor sweeper, industrial sweeper, commercial floor cleaning machine, warehouse floor sweeper.</p>
    `,
    pdf: pdfFile21,
  },
  {
    id: 22,
    productName: "WS2000A",
    image: ws2000a,
    imageg: ws2000a1,
    description: `
    <p>The Ride-On Sweeper WS 2000A is designed to handle the toughest sweeping jobs in large industrial and commercial environments. This ride-on sweeper features a powerful motor, a large sweeping path, and a durable design, making it ideal for facilities such as warehouses, factories, and large parking areas.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Powerful Motor:</strong> Provides industrial-grade sweeping capabilities for rigorous tasks.</li>
      <li><strong>Large Sweeping Path:</strong> Ensures extensive coverage for faster cleaning operations.</li>
      <li><strong>Robust Construction:</strong> Built for long-lasting durability in demanding environments.</li>
      <li><strong>Ideal Use:</strong> Perfect for large industrial and commercial spaces.</li>
    </ul>
    <p><strong>Keywords:</strong> ride-on sweeper, industrial sweeper, commercial floor cleaning machine, warehouse floor sweeper.</p>
  `,
    pdf: pdfFile22,
  },
  {
    id: 23,
    productName: "ELECTRIC WALK BEHIND SWEEPER WS-45L",
    image: ws45l,
    imageg: ws45l1,
    description: `
    <p>The Electric Walk-Behind Sweeper WS-45L is a versatile and efficient cleaning machine designed for commercial and industrial applications. This walk-behind sweeper is equipped with a powerful motor and a large dustbin, ensuring that large areas are cleaned quickly and effectively. The WS-45L is perfect for maintaining cleanliness in warehouses, office buildings, and shopping centers.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Powerful Motor:</strong> Provides effective sweeping for thorough cleaning.</li>
      <li><strong>Large Dustbin:</strong> Offers extended cleaning capacity to minimize downtime.</li>
      <li><strong>Compact Design:</strong> Ensures easy maneuverability in tight spaces.</li>
      <li><strong>Suitable Use:</strong> Ideal for both commercial and industrial spaces.</li>
    </ul>
    <p><strong>Keywords:</strong> electric walk-behind sweeper, industrial sweeper, commercial floor cleaning machine, warehouse floor sweeper.</p>
  `,
    pdf: pdfFile23,
  },
  {
    id: 24,
    productName: "MANUAL SWEEPER  MACHINE WS-920",
    image: ws920,
    imageg: ws9201,
    description: `
    <p>The Manual Sweeper Machine WS-920 is a cost-effective solution for maintaining cleanliness in smaller commercial and industrial spaces. This manual sweeper is easy to operate and requires no power, making it a sustainable choice for environmentally conscious businesses. Its compact design allows for easy storage and use in tight spaces.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Manual Operation:</strong> No power required, promoting an eco-friendly cleaning solution.</li>
      <li><strong>Compact Design:</strong> Ensures easy storage in tight spaces.</li>
      <li><strong>Durable Construction:</strong> Built for long-lasting use in various environments.</li>
      <li><strong>Ideal Use:</strong> Perfect for small to medium-sized commercial spaces.</li>
    </ul>
    <p><strong>Keywords:</strong> manual sweeper, commercial floor cleaning machine, industrial sweeper, eco-friendly cleaning machine.</p>
  `,
    pdf: pdfFile24,
  },
  {
    id: 25,
    productName: "3 in 1 Automatic Carpet Cleaning Machin",
    image: cc,
    imageg: cc1,
    description: `
    <p>The Three-in-One Automatic Carpet Cleaning Machine is a versatile and powerful cleaning solution designed for high-traffic areas like banquets, theaters, offices, and temples. This state-of-the-art machine simplifies the carpet cleaning process by combining three essential functions: washing, scrubbing, and drying, all in one compact unit. Its robust design and advanced technology ensure that carpets are left spotless, sanitized, and dry in no time, making it an indispensable tool for maintaining cleanliness in large venues.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Three-in-One Functionality:</strong> Washes, scrubs, and dries carpets in a single operation, ensuring thorough cleaning and quick drying times.</li>
      <li><strong>High Efficiency:</strong> Capable of handling large areas, making it ideal for use in high-traffic environments like banquets and theaters.</li>
      <li><strong>User-Friendly Design:</strong> Easy to operate, even for non-professional employees, with minimal training required.</li>
      <li><strong>Versatile Application:</strong> Suitable for various settings, including offices and temples, where maintaining clean carpets is crucial for a pleasant environment.</li>
    </ul>
    <p>Invest in the Three-in-One Automatic Carpet Cleaning Machine to streamline your carpet maintenance routine and ensure that your carpets remain pristine and inviting, even in the busiest locations.</p>
  `,
    pdf: pdfFile25,
  },

  {
    id: 26,
    productName: "WEB AND DRY VACUUM CLEANERR ULTIMO-15",
    image: v1,
    imageg: v1,
    description: `
    <p>The Web and Dry Vacuum Cleaner ULTIMO-15 is a versatile and powerful cleaning solution, designed to tackle both dry and wet cleaning tasks effortlessly. Whether it’s cleaning up dust, debris, or liquid spills, the ULTIMO-15 ensures thorough and efficient cleaning for various environments such as offices, hotels, workshops, and more. Its compact design and robust suction make it an ideal choice for both small and large-scale cleaning tasks.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Dual Functionality:</strong> Capable of handling both wet and dry cleaning tasks, making it versatile for different cleaning needs.</li>
      <li><strong>Powerful Suction:</strong> Equipped with a high-performance motor that provides strong suction to effectively remove dust, dirt, and liquid spills.</li>
      <li><strong>Compact and Portable:</strong> Lightweight design with wheels for easy maneuverability, making it suitable for use in tight spaces and larger areas alike.</li>
      <li><strong>Large Capacity:</strong> The ULTIMO-15 features a generous dust and liquid storage tank, allowing for extended cleaning sessions without frequent emptying.</li>
      <li><strong>Durable Construction:</strong> Built with durable materials to withstand heavy-duty use in commercial and industrial environments.</li>
    </ul>
    <p>The Web and Dry Vacuum Cleaner ULTIMO-15 is the ultimate tool for maintaining clean and safe environments, offering a reliable and efficient solution for a variety of cleaning tasks. Its user-friendly design ensures that even non-professional employees can operate it with ease, making it a valuable addition to any cleaning routine.</p>
  `,
    pdf: pdfFile26,
  },
  {
    id: 27,
    productName: "WEB AND DRY VACUUM CLEANERR ULTIMO-30",
    image: v2,
    imageg: v2,
    description: `
    <p>The Web and Dry Vacuum Cleaner ULTIMO-30 is a robust and efficient cleaning machine, designed to handle both wet and dry cleaning tasks with ease. Perfect for commercial and industrial environments, the ULTIMO-30 offers superior performance for tackling various cleaning challenges, from dust and debris to liquid spills. Its powerful design makes it an essential tool for maintaining cleanliness in larger spaces such as warehouses, factories, and large office complexes.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Versatile Cleaning:</strong> Capable of managing both wet and dry materials, ensuring comprehensive cleaning in diverse settings.</li>
      <li><strong>High Suction Power:</strong> Features a powerful motor that delivers strong suction, making it highly effective for thorough cleaning tasks.</li>
      <li><strong>Spacious Capacity:</strong> Designed with a large tank for dust and liquids, allowing for extended operation without the need for frequent emptying.</li>
      <li><strong>Easy Maneuverability:</strong> Equipped with wheels and a lightweight design, making it easy to transport and use in various locations.</li>
      <li><strong>Durable and Reliable:</strong> Constructed from high-quality materials to withstand heavy usage in demanding environments.</li>
    </ul>
    <p>The Web and Dry Vacuum Cleaner ULTIMO-30 is an indispensable cleaning solution, ideal for professional cleaning services and facilities management. Its user-friendly features and powerful performance ensure effective maintenance of clean and safe work environments.</p>
  `,
    pdf: pdfFile26,
  },
  {
    id: 28,
    productName: "WEB AND DRY VACUUM CLEANERR ULTIMO-60",
    image: v3,
    imageg: v3,
    description: `
    <p>The Web and Dry Vacuum Cleaner ULTIMO-60 is a powerful and versatile cleaning machine, engineered to tackle both wet and dry cleaning tasks effortlessly. This industrial-grade vacuum is perfect for large commercial spaces, including factories, warehouses, and shopping centers, providing outstanding performance for a variety of cleaning challenges, from spills to debris.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Dual Functionality:</strong> Capable of efficiently handling both wet and dry waste, making it ideal for diverse cleaning applications.</li>
      <li><strong>Robust Suction Power:</strong> Powered by a high-performance motor that delivers exceptional suction, ensuring thorough cleaning results.</li>
      <li><strong>Large Capacity Tank:</strong> Designed with an expansive tank to accommodate significant amounts of waste, reducing downtime for emptying.</li>
      <li><strong>Enhanced Mobility:</strong> Features durable wheels and a lightweight build for easy transportation across different work areas.</li>
      <li><strong>Heavy-Duty Construction:</strong> Made from high-quality materials for durability, ensuring reliable performance in demanding environments.</li>
    </ul>
    <p>The Web and Dry Vacuum Cleaner ULTIMO-60 is the ultimate cleaning solution for professionals, ensuring a pristine and safe working environment in large-scale settings. With its user-friendly features and powerful capabilities, it stands as an essential tool for any cleaning operation.</p>
  `,
    pdf: pdfFile26,
  },
  {
    id: 29,
    productName: "WEB AND DRY VACUUM CLEANERR ULTIMO-80",
    image: v4,
    imageg: v4,
    description: `
    <p>The Web and Dry Vacuum Cleaner ULTIMO-80 is an advanced cleaning solution designed for high-performance cleaning in large commercial and industrial environments. This powerful vacuum excels at managing both wet and dry waste, making it ideal for a variety of applications such as factories, warehouses, and construction sites.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>High Suction Power:</strong> Equipped with a robust motor that provides superior suction strength for effective cleaning of all types of debris.</li>
      <li><strong>Large Capacity Tank:</strong> Features an expansive tank that minimizes the need for frequent emptying, perfect for extensive cleaning jobs.</li>
      <li><strong>Dual Cleaning Capability:</strong> Efficiently handles wet spills and dry waste, ensuring a thorough clean regardless of the environment.</li>
      <li><strong>Maneuverable Design:</strong> Designed with durable wheels for effortless movement and transport across different work areas.</li>
      <li><strong>Durable Construction:</strong> Built from high-quality materials to withstand the rigors of industrial cleaning, ensuring long-lasting performance.</li>
    </ul>
    <p>The Web and Dry Vacuum Cleaner ULTIMO-80 is your go-to solution for maintaining a clean and safe working environment. With its powerful features and user-friendly design, it is the ideal choice for professionals seeking efficiency and reliability in their cleaning equipment.</p>
  `,
    pdf: pdfFile26,
  },
  {
    id: 30,
    productName: "WEB AND DRY VACUUM CLEANERR WC-20",
    image: v5,
    imageg: v5,
    description: `
    <p>The Web and Dry Vacuum Cleaner WC-20 is a versatile and compact cleaning solution designed for both commercial and residential environments. Ideal for tackling everyday cleaning challenges, this vacuum excels in managing both wet and dry debris, ensuring a comprehensive clean in a variety of settings.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>Powerful Suction:</strong> Features a robust motor that delivers impressive suction power for efficient cleaning of all types of surfaces.</li>
      <li><strong>Compact and Lightweight:</strong> Designed for easy handling and storage, making it suitable for tight spaces and smaller areas.</li>
      <li><strong>Wet and Dry Functionality:</strong> Capable of cleaning both wet spills and dry dust, providing versatility for various cleaning tasks.</li>
      <li><strong>User-Friendly Controls:</strong> Equipped with intuitive controls for easy operation, making it accessible for all users.</li>
      <li><strong>Durable Design:</strong> Built from high-quality materials to ensure longevity and withstand regular use.</li>
    </ul>
    <p>The Web and Dry Vacuum Cleaner WC-20 is the perfect companion for maintaining cleanliness in any environment. Its efficient features and user-friendly design make it a reliable choice for both home and professional cleaning tasks.</p>
  `,
    pdf: pdfFile27,
  },
  {
    id: 31,
    productName: "WEB AND DRY VACUUM CLEANERR WC-20SF",
    image: v6,
    imageg: v6,
    description: `
    <p>The Web and Dry Vacuum Cleaner WC-20SF is a powerful and efficient cleaning tool designed for both commercial and residential use. Its innovative design allows for seamless cleaning of wet and dry debris, making it an essential solution for various environments.</p>
    <p><strong>Key Features:</strong></p>
    <ul>
      <li><strong>High Suction Power:</strong> Equipped with a robust motor, this vacuum offers exceptional suction strength for thorough cleaning.</li>
      <li><strong>Dual Functionality:</strong> Effectively handles both wet spills and dry dirt, providing flexibility for a wide range of cleaning tasks.</li>
      <li><strong>Compact and Portable:</strong> Designed with portability in mind, the WC-20SF is lightweight and easy to maneuver, perfect for quick clean-ups.</li>
      <li><strong>User-Friendly Design:</strong> Features intuitive controls that make operation simple and efficient for users of all skill levels.</li>
      <li><strong>Durable Construction:</strong> Made from high-quality materials to ensure long-lasting performance, suitable for regular use in demanding environments.</li>
    </ul>
    <p>The Web and Dry Vacuum Cleaner WC-20SF is the ideal choice for maintaining cleanliness and hygiene in any setting. Its powerful features and versatile design make it a reliable partner for both everyday and intensive cleaning tasks.</p>
  `,
    pdf: pdfFile28,
  },
  {
    id: 32,
    productName: "WEB AND DRY VACUUM CLEANER WC-40",
    image: v7, // Add image path here
    imageg: v7, // Add image path here
    description: `
      <p>The Web and Dry Vacuum Cleaner WC-40 is a versatile and robust cleaning solution designed to handle a wide variety of wet and dry cleaning tasks. Built for efficiency and durability, this vacuum cleaner is perfect for use in both industrial and commercial environments.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Powerful Performance:</strong> Equipped with a strong motor that delivers impressive suction power, ensuring effective removal of dirt and liquids.</li>
        <li><strong>Wet and Dry Capabilities:</strong> Capable of cleaning both wet spills and dry debris, making it suitable for various cleaning scenarios.</li>
        <li><strong>Large Capacity Tank:</strong> The WC-40 features a spacious tank, reducing the need for frequent emptying during large-scale cleaning tasks.</li>
        <li><strong>Portable and Sturdy Design:</strong> Its sturdy construction ensures long-term reliability, while the design ensures ease of movement for quick and efficient cleaning.</li>
        <li><strong>Easy to Use:</strong> Intuitive controls make operation simple, even for first-time users.</li>
      </ul>
      <p>Whether you need to clean up liquid spills or remove dry debris, the Web and Dry Vacuum Cleaner WC-40 is the perfect cleaning companion for your commercial or industrial space.</p>
    `,
    pdf: pdfFile27,
  },
  {
    id: 33,
    productName: "WEB AND DRY VACUUM CLEANER WC-40SF",
    image: v8, // Add image path here
    imageg: v8, // Add image path here
    description: `
      <p>The Web and Dry Vacuum Cleaner WC-40SF is a highly efficient and versatile cleaning machine, specifically designed for both wet and dry applications. With its powerful suction and durable build, this vacuum cleaner is an excellent choice for various commercial and industrial cleaning tasks.</p>
      <p><strong>Key Features:</strong></p>
      <ul>
        <li><strong>Superior Suction Power:</strong> Featuring a robust motor that ensures maximum suction for effective cleaning of wet spills and dry debris.</li>
        <li><strong>Wet and Dry Functionality:</strong> Ideal for handling different types of messes, making it suitable for diverse cleaning environments.</li>
        <li><strong>Compact and Ergonomic Design:</strong> The WC-40SF is designed for easy maneuverability, allowing for efficient cleaning in tight spaces.</li>
        <li><strong>Durable Construction:</strong> Built with high-quality materials to withstand heavy use in commercial settings.</li>
        <li><strong>Easy Maintenance:</strong> The vacuum features simple maintenance protocols to ensure longevity and consistent performance.</li>
      </ul>
      <p>Whether you need to tackle spills or remove dust and dirt, the Web and Dry Vacuum Cleaner WC-40SF is the perfect tool for maintaining cleanliness in any industrial or commercial space.</p>
    `,
    pdf: pdfFile28,
  },
];

export { ProductDetailList };
